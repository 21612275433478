<template>
  <div>
    <a @click="clickRouteLanding">
      <div class="button-close has-text-right mr-5 mt-5">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 1L1 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L25 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </a>

    <div class="container-content-create-account has-text-left mt-6">

      <div class="title-header-section-create-account">
        <p class="title-section text-basier has-text-left title-regular dark-blue-emitus">Ingreso</p>
      </div>

      <form class="form-login">
        <!-- user -->
        <b-field class="mt-5" label="Email">
          <b-input
            placeholder="tuemail@algo.com"
            v-model="email"
            type="email"
          >
          </b-input>
        </b-field>
        <!-- Pasword -->
        <b-field class="mt-5" label="Contraseña">
          <b-input type="password" v-model="password" password-reveal> </b-input>
        </b-field>

        <!-- reset password -->
         <div class="mt-1">
          <!-- <a href="https://api.whatsapp.com/send?phone=+34643943764&text=Hola, Nececito mas informacion!" target="_blank" class="text-basier" style="font-size: 13px; color: #9A55F2;" @click="resetPassword">Did you forget your password2? <span style="font-weight: bold; text-decoration: underline;">Click here</span> </a> -->
          <a class="text-basier" style="font-size: 13px; color: #9A55F2;" @click="resetPassword">¿Olvidaste tu contraseña?<span style="font-weight: bold; text-decoration: underline; color: #000;">Click aquí</span> </a>
        </div>

        <!-- button login -->
        <button class="mt-6 button-primary-medium" @click.prevent="login">Ingreso</button>
        <div style="margin-top: 59px;">
          <router-link :to="{name: 'Signup'}" class="text-basier" style="font-size: 13px; color: #393939;">Aún no tengo una cuenta<span style="font-weight: bold; text-decoration: underline; color: #9A55F2;">Registrarme</span> </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import firebase from 'firebase/app'
import 'firebase/auth'
import dayjs from 'dayjs'
import MixpanelService from '@/services/MixpanelService'
import mixpanel from 'mixpanel-browser'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'Login',
  metaInfo: {
    title: 'emitus | Login'
  },
  data () {
    return {
      email: '',
      password: '',
      user: {}
    }
  },
  created () {
  },
  methods: {
    clickRouteLanding () {
      if (this.$router.history._startLocation === '/login') {
        this.$router.push('/')
      } else {
        history.go(-1)
      }
    },
    // Login with Password --------
    async login () {
      const timezone = dayjs.tz.guess()
      await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .catch(err => {
          this.danger(err)
        })

      await firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          await UserService.SigninFirebase(idToken, timezone)
            .then(response => {
              if (response.status === 200) {
                console.log('00', response)
                // mixpanel.identify(`${response.data._id}`)
                MixpanelService.login(response.data)
                this.notify.$emit('updateData')
              }
            })
          await UserService.getCurrentUser(idToken)
            .then(user => {
              this.user = user.data
              this.notify.$emit('updateData')
            })
          if (this.$router.history._startLocation === `/${this.user.username}`) {
            this.$router.push(`/${this.user.username}`)
          } else {
            this.$router.push('/panel/me/edit-me')
          }
        })
    },

    // Reset Password -------
    async resetPassword () {
      await firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.okReset('Check your inbox')
        })
        .catch((error) => {
          const errorMessage = error.message
          this.danger(errorMessage)
        })
    },
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    okReset (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    }
  }
}
</script>

<style scoped>

.title-header-section-create-account {
  margin-top: 73px;
  margin-left: 5%;
}

.form-login {
  margin-top: 70px;
}

.container-content-create-account {
  width: 85%;
}

form {
  width: 90%;
  margin: auto;
  max-width: 360px;
}

.label:not(:last-child) {
    margin-bottom: 0.3em !important;
}

</style>
